import React from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Stack } from "react-bootstrap";
import { Bucket } from "../../../../api/config/apiConfig";
import gifLoading from "../../../../imgs/giphy.gif";

const PrebidFormDownload = ({ formContent }) => {
  const { uuid } = useParams();

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const ordinalSuffix = getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} ${month}, ${year}`;
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return (
    <>
      <div className="mx-4">
        <div className="d-flex align-items-center justify-content-center w-100">
          <p className="ps-0 mb-0 head-top">
            {"Prebid Agreement For import of vehicle"}
          </p>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Issue Date:</b> <span>{`${formContent.issueDate}`}</span>
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Confidentiality Notice: </b>{" "}
              <span>{`${formContent.notice}`}</span>
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>CONTRACT PRICE: </b>{" "}
              <span>{`${formContent.contractPrice}`}</span>
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>DATE: </b> <span>{`${formatDate(formContent.date)}`}</span>
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Introduction: </b>{" "}
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span>
                {`This document outlines the PRE- Bidding Agreement for the Import
                of a Vehicle as per the contractual agreement established
                between the parties involved. This agreement, made at Islamabad
                on the date ${formatDate(formContent.date)}, is between:`}
              </span>{" "}
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>First Party: </b>{" "}
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>“Buyer”</b>{" "}
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span>{`${formContent.firstParty.name} (${formContent.firstParty.organization})
(Hereinafter referred to as "the 1st Party", which expression shall, where the context so
permits, include his heirs, executors, administrators, and assigns)`}</span>{" "}
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>And</b>{" "}
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Second Party:</b>{" "}
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span>
                “Shelozon Movers Packers & Logistics Pvt Ltd’’ (Hereinafter
                referred to as "the 2nd Party")
              </span>{" "}
            </p>
          </div>
        </div>
        <hr />
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Instructions to Bidders: </b>{" "}
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span>The 1st Party shall provide the following documents: </span>{" "}
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>1. Passport Copy: </b>
              <span>
                A clear and legible copy of the passport of the authorized
                representative.
              </span>{" "}
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>2. Exemption Certificate: </b>
              <span>
                An official exemption certificate or import permit issued by the
                Ministry of Foreign Affairs.
              </span>{" "}
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>3. Copy of Diplomatic ID: </b>
              <span>
                A copy of the diplomatic identification card of the authorized
                representative.
              </span>{" "}
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>4. Authority Letter:</b>
              <span>
                o An authority letter issued by the Embassy/Organization,
                authorizing the collection of the consignment. o Authority
                Letter for Collecting and Clearing Consignment from Destination
                Port.
              </span>{" "}
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>5. Specimen Signature: </b>
              <span>
                A specimen signature of the authorized representative for
                verification purposes.
              </span>{" "}
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>6. Request Letter to Collector of Customs: </b>
              <span>
                A formal request letter addressed to the Collector of Customs,
                detailing the consignment and requesting necessary actions.
              </span>{" "}
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>7. Letter to Excise and Taxation Officer Karachi: </b>
              <span>
                A letter addressed to the Excise and Taxation Officer in
                Karachi, pertaining to the consignment.
              </span>{" "}
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>8. Undertaking for Transportation:</b>
              <span>
                A letter of undertaking, ensuring the transportation of the
                consignment is conducted as per the regulations and
                requirements.
              </span>{" "}
            </p>
          </div>
        </div>

        <hr />
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>INCLUSIONS: </b>{" "}
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>2nd Party: </b>{" "}
              <span>
                Is responsible for the following services and its costs are
                included in the Contract Price:
              </span>
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span>• Purchase of Vehicle in Japan Auction</span>
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span>
                • Export documentation, Taxes, Auction Fees and Handling in
                Japan
              </span>
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span>
                • Sea Freight: Shipping costs from Yokohama/Nagoya, Japan to
                Karachi Port, Pakistan.
              </span>
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span>
                • Destination Terminal Handling Charges (DTHC): Charges for
                handling the vehicle at the destination terminal in Karachi,
                Pakistan{" "}
              </span>
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span>• Wharfage Charges</span>
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span>
                • DO Charges: Delivery Order charges, necessary for the release
                of the cargo.
              </span>
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span>
                • Transportation: Costs of transporting the vehicle from the
                port of Karachi to Islamabad.
              </span>
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span>
                • Handling Charges during Local Transportation: Charges for
                handling the vehicle during its transportation from Karachi to
                Islamabad.
              </span>
            </p>
          </div>
        </div>

        <hr />
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>EXCLUSIONS: </b>{" "}
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span>
                At the destination (Port of Karachi, Pakistan), the above-stated
                price excludes the following costs:{" "}
              </span>{" "}
            </p>
          </div>
        </div>
        <hr />
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Demurrage: </b>{" "}
              <span>
                Demurrage refers to official storage charges imposed by the port
                authority for cargo that remains at the port beyond the allowed
                free time. Detention Charges: Fees for the extended use of
                containers beyond the agreed free period.
              </span>
            </p>
          </div>
        </div>
        <hr />
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>PAYMENT TERMS:</b>{" "}
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span>
                The payment for the import of the vehicle shall be made as
                follows:
              </span>
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>1. 50% Payment at the Time of Bill of Lading:</b>
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span>
                price shall be paid at the submission of the Bill of Lading for
                the vehicle to the 1st Party (Buyer).
              </span>
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>2. 50% Payment at the Time of Delivery:</b>
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span>
                The remaining 50% of the total contract price shall be paid upon
                the delivery of the vehicle to the 1 st Party (Buyer) designated
                location in Islamabad.
              </span>
            </p>
          </div>
        </div>

        <hr />
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>CANCELLATION POLICY:</b>{" "}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span className="ms-1">
                1. In case the Buyer tends to cancel the agreement after the
                purchase and during the time of issue of the Bill of Lading, the
                Buyer is bound to pay the total amount of Contract Price
              </span>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span className="ms-1">
                2. If buyer cancels After the Issuance of Bill of Lading and
                before delivery of Vehicle, the Buyer will pay 50% of remaining
                amount of Total Contract Price.
              </span>
            </p>
          </div>
        </div>
        <hr />
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>APPENDIX:</b>{" "}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <span className="ms-1">
                The 1st Party (Buyer) shall review the following documents and
                ensure their completeness:
              </span>

              <b>Pictures of the Vehicle:</b>
              <span className="ms-1">
                Comprehensive photographs of the vehicle from various angles,
                including interior and exterior views. These pictures serve as
                visual documentation of the vehicle's condition at the time of
                booking.
              </span>
              <b>Auction Sheet of the Vehicle:</b>
              <span className="ms-1">
                The Auction Sheet provides detailed information about the
                vehicle's specifications and condition.
              </span>
            </p>
          </div>
        </div>
        <hr />
        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b> Car Description:</b>{" "}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Make:</b>{" "}
              <span className="ms-1">
                {" "}
                {formContent?.carDescription?.make
                  ? formContent?.carDescription?.make
                  : "-"}
              </span>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Model:</b>{" "}
              <span className="ms-1">
                {" "}
                {formContent?.carDescription?.model
                  ? formContent?.carDescription?.model
                  : "-"}
              </span>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Specification:</b>{" "}
              <span className="ms-1">
                {" "}
                {formContent?.carDescription?.specification
                  ? formContent?.carDescription?.specification
                  : "-"}
              </span>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Mileage:</b>{" "}
              <span className="ms-1">
                {" "}
                {formContent?.carDescription?.mileage
                  ? formContent?.carDescription?.mileage
                  : "-"}
              </span>
            </p>
          </div>
        </div>

        <Row
          className="bg-white my-4 mx-0  p-3 py-4 mb-4"
          style={{ borderRadius: "10px" }}
        >
          <Row>
            {/* Images */}
            {/* <Col sm={6} md={6} className="px-10"> */}
            <div className="d-flex flex-wrap">
              {formContent?.carDescription.images?.map((image, index) => {
                return (
                  <Stack key={index}>
                    <img
                      key={index}
                      src={`${Bucket}${image}`}
                      placeholderSrc={gifLoading}
                      height={100}
                      width={100}
                      alt={`Image ${index}`}
                    />
                  </Stack>
                );
              })}
            </div>
          </Row>
        </Row>

        <hr />

        <div className="row ">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b> Important Note:</b>{" "}
              <span className="ms-1">
                {" "}
                {formContent?.importantNote ? formContent?.importantNote : "-"}
              </span>
            </p>
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>First Party(Buyer)</b>{" "}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Name:</b>{" "}
              <span className="ms-1">
                {" "}
                {formContent?.firstParty?.name
                  ? formContent?.firstParty?.name
                  : "-"}
              </span>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Email:</b>{" "}
              <span className="ms-1">
                {" "}
                {formContent?.firstParty?.email
                  ? formContent?.firstParty?.email
                  : "-"}
              </span>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>MOFA ID NO:</b>{" "}
              <span className="ms-1">
                {" "}
                {formContent?.firstParty?.mofaIdNo
                  ? formContent?.firstParty?.mofaIdNo
                  : "-"}
              </span>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Organization:</b>{" "}
              <span className="ms-1">
                {" "}
                {formContent?.firstParty?.organization
                  ? formContent?.firstParty?.organization
                  : "-"}
              </span>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>2nd Party</b>{" "}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="p-word mb-0 text-start">
              <b>Name:</b>{" "}
              <span className="ms-1">
                {" "}
                {formContent?.secondParty?.name
                  ? formContent?.secondParty?.name
                  : "-"}
              </span>
            </p>
          </div>
        </div>

        <Row className="mt-1">
          <Col sm={6} className="">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              {formContent.firstParty.sign != "" &&
                formContent.firstParty.sign != undefined && (
                  <img
                    src={`${Bucket}${formContent.firstParty.sign}`}
                    height={80}
                    width={200}
                  />
                )}
            </div>

            <p className="mb-0">{"_______________________________"}</p>
            <p className="my-0">
              <b>{"First Party Sign"}</b>
            </p>

            {""}
          </Col>

          <Col sm={6} className="">
            <div
              style={{ height: "90px" }}
              className="d-flex justify-content-center align-items-end"
            >
              {formContent.secondParty.sign?.trim()?.length == 0 ? (
                <div className="d-flex justify-content-center align-items-end"></div>
              ) : (
                <img
                  src={`${Bucket}${formContent.secondParty.sign}`}
                  height={80}
                  width={200}
                />
              )}
            </div>
            <p className="mb-0">{"_______________________________"}</p>
            <p className="my-0">
              <b>{"Shelozon Movers Packers & Logistics Pvt Ltd"}</b>
            </p>

            {""}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PrebidFormDownload;
